import React, {useEffect} from "react";
import Breadcrumbs from "../breadcrumbs";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { graphql, useStaticQuery } from 'gatsby';
import { getBrText } from "../../helpers/helper.rendering";
import img from '../../images/somos2.jpg'

const Main = () => {

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            about_us
            sections{
                about{
                    image
                    sup_title
                    title
                    paragraph
                }
            }
        }
    }`)
    
    useEffect(() => {
        AOS.init({ duration : 2000});
    }, [])

    return (
        <section className="about-main">
            <div className="container-fluid">
                <div className="row content-main d-flex align-items-center">
                    <div className="col-12">
                        <div className="row justify-content-between">
                            <div className="col-12 div-breadcrums">
                                <Breadcrumbs
                                    props={[
                                    { name: realEstate.name, route: "/", location: "" },
                                    { name: "Nosotros", route:   "", location: ""},
                                    ]}
                                />
                            </div>
                            {/* <div className="d-none d-lg-block col-lg-6 px-lg-0 text-end w-100 mb-5">
                                <span>sobre nosotros   <span className="no-ls"> ——— </span>   quiénes somos </span>
                            </div> */}
                            <div className="col-lg-12">
                                <h1>
                                   {realEstate.sections.about.sup_title}
                                </h1>
                            </div>
                            <div className="col-lg-12">
                                <h2>
                                    {realEstate.sections.about.title}
                                </h2>
                            </div>
                            <div className="col-lg-12 div-text">
                                <p>
                                    {realEstate.sections.about.paragraph}
                                </p>
                            </div>
                            <div className="col-12 div-img">
                                <img className="w-100" src={img} alt="sobre nosotros" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
} 

export default Main;